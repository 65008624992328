import Logo from '../assets/logo.svg';
import Flow from '../assets/fluxo.png';
import Generativo from '../assets/generativo.png';
import Wpp from '../assets/whatsapp.png';
import {useState} from "react";

function Home() {
    const [success, setSuccess] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {
            'first-name': firstName,
            'last-name': lastName,
            company,
            'phone-number': phone,
            message,
            email
        } = e.target.elements;
        let details = {
            firstName: firstName?.value,
            lastName: lastName?.value,
            company: company?.value,
            phone: phone?.value,
            message: message?.value,
            email: email?.value,
        };

        let response = await fetch("https://admin-dev.verbeux.com.br/mail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        if (response.status < 300) {
            setSuccess(true)
        }

        setLoaded(true);
    };

    return (
        <>
            <div className="bg-white">
                {/* Barra de navegação */}
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Verbeux</span>
                                <img className="h-8 w-auto"
                                     src={Logo} alt="logo"/>
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button type="button"
                                    clas
                                    sName="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
                                <span className="sr-only">Abrir menu</span>
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                     stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                                </svg>
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            <a href="#products" className="text-sm font-semibold leading-6 text-gray-900">Produto</a>
                            <a href="#features"
                               className="text-sm font-semibold leading-6 text-gray-900">Funcionalidades</a>
                            <a href="#aboutus" className="text-sm font-semibold leading-6 text-gray-900">Sobre</a>
                            <a href="#contactus" className="text-sm font-semibold leading-6 text-gray-900">Contato</a>
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:gap-x-8 lg:justify-end">
                            <div className={'flex lg:gap-x-2 items-center'}>
                                <a href="https://docs.verbeux.com.br/"
                                   className="font-medium text-blue-600 dark:text-blue-500 flex items-center gap-1.5">
                                    Docs
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 align-middle">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"/>
                                    </svg>
                                </a>
                            </div>
                            <a href="https://admin.verbeux.com.br"
                               className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Acessar &rarr;</a>
                        </div>
                    </nav>
                </header>

                {/* BLOCO 1 */}
                <div className="relative isolate px-6 pt-14 lg:px-8">
                    <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                         aria-hidden="true">
                        <div
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
                    </div>
                    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                            <div
                                className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                Transforme seu negócio facilitando a comunicação,  <a href="#features"className="font-semibold text-purple-600"><span
                                className="absolute inset-0" aria-hidden="true"></span>Saiba mais!<span
                                aria-hidden="true">&rarr;</span></a>
                            </div>
                        </div>
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Venha agora
                                construir seus assistentes virtuais!</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">Oferecemos a melhor e mais completa plataforma para criação e personalização do seu assistente virtual.</p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a href="https://wa.me/5585999386799" target="_blank" rel="noopener noreferrer"
                                   className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Falar com especialista </a>
                                <a href="#features" className="text-sm font-semibold leading-6 text-gray-900">Saber
                                    mais <span
                                        aria-hidden="true">→</span></a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true">
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
                    </div>
                </div>

                {/* BLOCO 2 */}
                <div id="products" className="overflow-hidden bg-gray-800 py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div
                            className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <div className="lg:pr-8 lg:pt-4">
                                <div className="lg:max-w-lg">
                                    <h2 className="text-base font-semibold leading-7 text-purple-600">Assistente por</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">Fluxo</p>
                                    <p className="mt-6 text-lg leading-8 text-gray-100">Crie seu assistente de forma rápida. Estruture o fluxo como uma conversa com seu cliente e já saia usando seu assistente.</p>
                                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                        {/* <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg className="absolute left-1 top-1 h-5 w-5 text-purple-600"
                                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path
                                                        d="M12 .75a8.25 8.25 0 0 0-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 0 0 .577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 0 1-.937-.171.75.75 0 1 1 .374-1.453 5.261 5.261 0 0 0 2.626 0 .75.75 0 1 1 .374 1.452 6.712 6.712 0 0 1-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 0 0 .577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0 0 12 .75Z"/>
                                                </svg>

                                                Design inovador
                                            </dt>
                                            <dd className="inline text-gray-400"> Navegue com facilidade pelo nosso
                                                criador de fluxo e
                                                crie assistentes que pensam e reagem com a cara e tom do seu negócio.
                                            </dd>
                                        </div> */}
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="absolute left-1 top-1 h-5 w-5 text-purple-600">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
                                                </svg>
                                                 Sem código
                                            </dt>
                                            <dd className="inline text-gray-400"> Construa assistentes sem precisar utilizar uma linha de código para isso</dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                     fill="currentColor"
                                                     className="absolute left-1 top-1 h-5 w-5 text-purple-600">
                                                    <path fillRule="evenodd"
                                                          d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm14.25 6a.75.75 0 0 1-.22.53l-2.25 2.25a.75.75 0 1 1-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 1 1 1.06-1.06l2.25 2.25c.141.14.22.331.22.53Zm-10.28-.53a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06L8.56 12l1.72-1.72a.75.75 0 1 0-1.06-1.06l-2.25 2.25Z"
                                                          clipRule="evenodd"/>
                                                </svg>

                                                Integração
                                            </dt>
                                            <dd className="inline text-gray-400"> Construa a solução integrando com os serviços que seu cliente já utiliza. </dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                     fill="currentColor"
                                                     className="absolute left-1 top-1 h-5 w-5 text-purple-600">
                                                    <path fillRule="evenodd"
                                                          d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                                iFrame
                                            </dt>
                                            <dd className="inline text-gray-400"> Seu cliente tambem pode editar o assistente de forma rápida e fácil.
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <img src={Flow}
                                 alt="Chatbot de Fluxo"
                                 className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                 width="2432" height="1442"/>

                        </div>
                    </div>
                </div>



                {/* BLOCO 2.1 */}
                <div className="overflow-hidden bg-gray-900 py-48 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div
                            className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <div className="lg:pr-8 lg:pt-4">
                                <div className="lg:max-w-lg">
                                    <h2 className="text-base font-semibold leading-7 text-purple-600">Assistente por</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">Generativo</p>
                                    <p className="mt-6 text-lg leading-8 text-gray-100"> Utilize documentos (PDFs, planilhas, imagens ou vídeos) e deixe que a nossa IA generativa faça o trabalho de encontrar o que você precisa nos documentos que você forneceu.</p>
                                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="absolute left-1 top-1 h-5 w-5 text-purple-600">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 3.798v5.02a3 3 0 0 1-.879 2.121l-2.377 2.377a9.845 9.845 0 0 1 5.091 1.013 8.315 8.315 0 0 0 5.713.636l.285-.071-3.954-3.955a3 3 0 0 1-.879-2.121v-5.02a23.614 23.614 0 0 0-3 0Zm4.5.138a.75.75 0 0 0 .093-1.495A24.837 24.837 0 0 0 12 2.25a25.048 25.048 0 0 0-3.093.191A.75.75 0 0 0 9 3.936v4.882a1.5 1.5 0 0 1-.44 1.06l-6.293 6.294c-1.62 1.621-.903 4.475 1.471 4.88 2.686.46 5.447.698 8.262.698 2.816 0 5.576-.239 8.262-.697 2.373-.406 3.092-3.26 1.47-4.881L15.44 9.879A1.5 1.5 0 0 1 15 8.818V3.936Z" />
                                                </svg>

                                                 Use documentos
                                            </dt>
                                            <dd className="inline text-gray-400"> Seu assistente especializado no seu conteudo. Deixe que nossa IA aprenda e gere conexões com os documentos fornecidos</dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="absolute left-1 top-1 h-5 w-5 text-purple-600">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
                                                </svg>


                                                 Amplie conhecimentos
                                            </dt>
                                            <dd className="inline text-gray-400"> Basta adicionar novos documentos para que seu assistente aprenda ainda mais sobre o negócio</dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="absolute left-1 top-1 h-5 w-5 text-purple-600">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 0 1-3.476.383.39.39 0 0 0-.297.17l-2.755 4.133a.75.75 0 0 1-1.248 0l-2.755-4.133a.39.39 0 0 0-.297-.17 48.9 48.9 0 0 1-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97ZM6.75 8.25a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H7.5Z" />
                                                </svg>

                                                 Seu tom
                                            </dt>
                                            <dd className="inline text-gray-400"> Utilizando linguagem natural, diga como o seu assistente deve responder.</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <img src={Generativo}
                                 alt="Chatbot generativo"
                                 className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                 width="2432" height="1442"/>

                        </div>
                    </div>
                </div>


                {/* BLOCO 3 */}
                <div id="features" className="relative isolate my-16 px-6 pt-14 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base font-semibold leading-7 text-purple-600">Rápido, fácil e
                            personalizado</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Amplie suas
                            possibilidades com funcionalidades versáteis</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">Descubra o poder de nossa plataforma através
                            de um conjunto robusto de funcionalidades projetadas para atender às diversas necessidades
                            da sua empresa</p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div
                                        className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                                        <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
                                        </svg>

                                    </div>
                                    Assistentes por fluxo
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">Esta abordagem permite que você
                                    mapeie conversas detalhadas, proporcionando interações previsíveis e de alta
                                    precisão que refletem a voz e os valores da sua marca.
                                </dd>
                            </div>
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div
                                        className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                                        <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"/>
                                        </svg>
                                    </div>
                                    Assistente generativo
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    Esta solução revolucionária emprega algoritmos avançados para gerar diálogos
                                    dinâmicos, aprendendo atravéz de documentos, aúdios ou vídeos para oferecer
                                    respostas naturais e contextualizadas.
                                </dd>
                            </div>
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div
                                        className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                                        <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                        </svg>
                                    </div>
                                    99.99% SLA
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    Asseguramos uma plataforma de chatbot altamente disponível e confiável para manter
                                    suas
                                    operações funcionando sem interrupções.
                                </dd>
                            </div>
                            <div className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div
                                        className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                                        <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"/>
                                        </svg>

                                    </div>
                                    Painel de Insights
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">Monitore o
                                    desempenho do seu chatbot, entenda profundamente as interações dos usuários e tome
                                    decisões informadas com visualizações de dados intuitivas e métricas detalhadas em
                                    tempo real.
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {/* BLOCO 4 */}
                <div id="aboutus" className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                    <img
                        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                        alt=""
                        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"/>
                    <div
                        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                        aria-hidden="true">
                        <div
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                            style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
                    </div>
                    <div
                        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                        aria-hidden="true">
                        <div
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                            style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
                    </div>
                    <div className="container mx-auto px-4 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0">
                            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Sobre</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300 text-justify">Desde 2016, nossa jornada na Universidade de Fortaleza (UNIFOR) envolveu pesquisa em IA, focando em processamento de linguagem natural.
                            Trabalhamos em projetos significativos, incluindo um assistente para a Prefeitura de Fortaleza, colaborações com a IBM Research entre alguns outros.
                            Em 2023, nasceu a Verbeux, liderada por Matheus Mafra (CEO) e Pedro Ivo (CTO).
                            Nossa plataforma de IA conversacional visa transformar a comunicação com clientes,
                            oferecendo uma interface intuitiva para criar e gerenciar assistentes virtuais sem necessidade de programação.
                            A Verbeux é o início de uma jornada em soluções de IA acessíveis e eficazes. </p>
                        </div>
                        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                                {/*<a href="#">Open roles <span aria-hidden="true">&rarr;</span></a>*/}
                                {/*<a href="#">Internship program <span aria-hidden="true">&rarr;</span></a>*/}
                                {/*<a href="#">Our values <span aria-hidden="true">&rarr;</span></a>*/}
                                {/*<a href="#">Meet our leadership <span aria-hidden="true">&rarr;</span></a>*/}
                            </div>
                            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                                <div className="flex flex-col-reverse">
                                    <dt className="text-base leading-7 text-gray-300">Clientes</dt>
                                    <dd className="text-2xl font-bold leading-9 tracking-tight text-white">3</dd>
                                </div>
                                <div className="flex flex-col-reverse">
                                    <dt className="text-base leading-7 text-gray-300">Colaboradores</dt>
                                    <dd className="text-2xl font-bold leading-9 tracking-tight text-white">4</dd>
                                </div>
                                <div className="flex flex-col-reverse">
                                    <dt className="text-base leading-7 text-gray-300">Mensagens por dia</dt>
                                    <dd className="text-2xl font-bold leading-9 tracking-tight text-white">5000+</dd>
                                </div>
                                <div className="flex flex-col-reverse">
                                    <dt className="text-base leading-7 text-gray-300">Assistentes</dt>
                                    <dd className="text-2xl font-bold leading-9 tracking-tight text-white">10+</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>


                {/* BLOCO 5 */}
                <div id='contactus' className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div
                        class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-35rem]"
                        aria-hidden="true">
                        <div
                            class="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                            style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
                    </div>
                    <div class="mx-auto max-w-2xl text-center">
                        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Entrar em contato</h2>
                        <p class="mt-2 text-lg leading-8 text-gray-600">Envie uma mensagem agora para ter condições
                            especiais!</p>
                    </div>
                    <form action="#" onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label for="first-name"
                                       class="block text-sm font-semibold leading-6 text-gray-900">Nome</label>
                                <div class="mt-2.5">
                                    <input type="text" name="first-name" id="first-name" autocomplete="given-name"
                                           class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                            <div>
                                <label for="last-name"
                                       class="block text-sm font-semibold leading-6 text-gray-900">Sobrenome</label>
                                <div class="mt-2.5">
                                    <input type="text" name="last-name" id="last-name" autocomplete="family-name"
                                           class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="company"
                                       class="block text-sm font-semibold leading-6 text-gray-900">Empresa</label>
                                <div class="mt-2.5">
                                    <input type="text" name="company" id="company" autocomplete="organization"
                                           class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="email"
                                       class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
                                <div class="mt-2.5">
                                    <input type="email" name="email" id="email" autocomplete="email"
                                           class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="phone-number"
                                       class="block text-sm font-semibold leading-6 text-gray-900">Telefone</label>
                                <div class="mt-2.5">
                                    <input type="tel" name="phone-number" id="phone-number" autocomplete="tel"
                                           class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"/>
                                </div>
                            </div>
                            <div class="sm:col-span-2">
                                <label for="message"
                                       class="block text-sm font-semibold leading-6 text-gray-900">Mensagem</label>
                                <div class="mt-2.5">
                                <textarea name="message" id="message" rows="4"
                                          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="mt-10">
                            <button type="submit"
                                    class="block w-full rounded-md bg-purple-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Enviar
                                mensagem
                            </button>
                        </div>
                        {loaded && success && <div
                            className="flex items-center my-4 p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 text-green-400"
                            role="alert">
                            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                            </svg>
                            <span className="sr-only">Info</span>
                            <div>
                                <span className="font-medium">Sucesso!</span> Sua mensagem foi enviada com sucesso!
                            </div>
                        </div>}
                    </form>
                </div>
                <div className="fixed bottom-4 right-4 z-50">
                    <a href="https://wa.me/5585999386799" target="_blank" rel="noopener noreferrer">
                        <img src={Wpp} alt="WhatsApp" className="w-12 h-12"/>
                    </a>
                </div>
            </div>


            {/* FOOTER */}
            <footer className="bg-white">
                <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="md:flex md:justify-between">
                        <div className="mb-6 md:mb-0">
                            <a href="https://verbeux.com.br/" className="flex items-center">
                                <img src={Logo} className="h-8 mr-3"
                                     alt="Verbeux Logo"/>
                                <span
                                    className="self-center text-2xl font-semibold whitespace-nowrap">Verbeux</span>
                            </a>
                            <h2 className="text-sm mt-8 font-semibold text-gray-900 uppercase">Verbeux Servicos
                                LTDA</h2>
                            <p className={'text-gray-500 font-medium'}>46.214.362/0001-86</p>
                        </div>
                        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">Locais</h2>
                                <ul className="text-gray-500 font-medium">
                                    <li className="mb-4">
                                        <a href="https://admin.verbeux.com.br" className="hover:underline">Admin</a>
                                    </li>
                                    <li>
                                        <a href="https://admin-dev.verbeux.com.br" className="hover:underline">Admin
                                            Beta</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">Seguir</h2>
                                <ul className="text-gray-500 font-medium">
                                    <li className="mb-4">
                                        <a href="https://github.com/verbeux-ai"
                                           className="hover:underline ">Github</a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/verbeux.ai"
                                           className="hover:underline">Instagram</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Legal</h2>
                                <ul className="text-gray-500 font-medium">
                                    <li className="mb-4">
                                        <a href="#" className="hover:underline">Termo de privacidade</a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:underline">Termos &amp; Condições</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8"/>
                    <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center ">© 2023 <a
              href="https://flowbite.com/" className="hover:underline">Verbeux™</a>. All Rights Reserved.
          </span>
                        <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
                            <a href="#" className="text-gray-500 hover:text-gray-900">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="currentColor" viewBox="0 0 21 16">
                                    <path
                                        d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"/>
                                </svg>
                                <span className="sr-only">Discord community</span>
                            </a>
                            <a href="#" className="text-gray-500 hover:text-gray-900">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="currentColor" viewBox="0 0 20 17">
                                    <path fill-rule="evenodd"
                                          d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                                          clip-rule="evenodd"/>
                                </svg>
                                <span className="sr-only">Twitter page</span>
                            </a>
                            <a href="https://github.com/verbeux-ai" className="text-gray-500 hover:text-gray-900">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
                                          clip-rule="evenodd"/>
                                </svg>
                                <span className="sr-only">GitHub account</span>
                            </a>
                            <a href="#" className="text-gray-500 hover:text-gray-900">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                          d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"
                                          clip-rule="evenodd"/>
                                </svg>
                                <span className="sr-only">Dribbble account</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home;
